import dayjs from 'dayjs'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const numberFormatter = value => {
  const rtnValue = ` ${value}`
  return String(rtnValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const dateFormatter = (value, formatting = 'YYYY-MM-DD') => dayjs(new Date(value)).format(formatting)

export const errorFormatter = (error, defaultMessage) => {
  if (!error || !error.response || !error.response.data) {
    return defaultMessage
  }

  const { data } = error.response

  if (typeof data === 'string') {
    return data
  }
  if (data.error && typeof data.error === 'string') {
    return data.error
  }
  if (data.errors && typeof data.errors === 'object') {
    let rtnValue = ''
    Object.keys(data.errors).forEach(key => {
      rtnValue += `${data.errors[key][0]}\n`
    })
    return rtnValue
  }
  return defaultMessage
}

export const arrayJoinStr = (array, property, delimiter = ',') => {
  if (!array || !property) return null

  return array.map(m => m[property]).join(delimiter)
}
